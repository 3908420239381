import React, { useState } from "react";
import BG1 from "./assets/PANTALLA_INICIO.jpg";
import BG2 from "./assets/PANTALLA_TEAM_PLAYER_SUPPORT.jpg";
import BG3 from "./assets/PANTALLA_WHITE_PAPER.jpg";
import BG4 from "./assets/PANTALLA_OUR_TEAM.jpg";
import BG5 from "./assets/PANTALLA_FAQS.jpg";
import BG6 from "./assets/PANTALLA_EXTENDIDA_TEAM_PLAYER_SUPPORT.jpg";
import Twitter from "./assets/Icono1.png";
import Discord from "./assets/Icono2.png";
import Linkedin from "./assets/Linkedin.png";
import Boat from "./assets/instagram.png";
import Players from "./assets/PLAYERS.png";
import Supporters from "./assets/SUPPORTERS.png";
import Envelope from "./assets/ICONO_PIE_DE_PÁGINA.png";
import Solucion from "./assets/solucion.png";
import Supporter1 from "./assets/Supporter1.png";
import Supporter2 from "./assets/Supporter2.png";
import Supporter3 from "./assets/Supporter3.png";
import Supporter4 from "./assets/Supporter4.png";
import PlayingMode from "./assets/playing_mode.png";
import OurTeam from "./assets/our_team.png";
import MintAFan from "./assets/mint_a_fan.png";
import MintAPlayer from "./assets/mint_a_player.png";
import MintAPLayerBg from "./assets/PlayersBackground.jpg";
import MintAFanBg from "./assets/FansBackground.jpg";
import LogoPagina from "./assets/LogoPagina.png";

const HeaderItem = (props: {
  children: React.ReactNode;
  href: string;
  target?: string;
}) => (
  <a
    className="font-aldo text-yellow hover:underline text-lg underline-offset-4"
    target={props.target}
    href={props.href}
  >
    {props.children}
  </a>
);

const HeaderSocial = (props: {
  imgSource: string;
  href: string;
  className?: string;
}) => (
  <a
    href={props.href}
    target="_blank"
    rel="noopener noreferrer"
    className="group relative"
  >
    <img
      src={props.imgSource}
      alt="twitter"
      className={`h-6  ${props.className}`}
    />
    <div className="group-hover:bg-yellow bg-transparent h-[1px] right-0 left-0 rounded-md absolute mt-2" />
  </a>
);

const Header = (props: {
  children: React.ReactNode;
  menuOpened: boolean;
  setMenuOpened: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <div className="bg-[rgba(0,0,0,0.7)]">
      <div className="max-w-screen-2xl mx-auto relative py-6">
        <div className="px-4 hidden md:flex justify-between">
          <div className="flex items-center gap-2 md:gap-8 ">
            {props.children}
          </div>
          <div className="flex items-center gap-2 md:gap-4 ml-2 invisible md:visible">
            <HeaderSocial
              imgSource={Twitter}
              href="https://twitter.com/Rtgnft"
            />
            <HeaderSocial
              imgSource={Discord}
              href="https://discord.gg/roadtoglorynft"
            />
            <HeaderSocial
              imgSource={Boat}
              href="https://www.instagram.com/RTGNFT/"
            />
          </div>
        </div>
        <div className="md:hidden">
          <button
            className="px-4 flex flex-col gap-1 hover:scale-105 transition-transform"
            onClick={() => props.setMenuOpened((isOpen) => !isOpen)}
          >
            <div className="w-8 h-1 bg-yellow rounded-md" />
            <div className="w-8 h-1 bg-yellow rounded-md" />
            <div className="w-8 h-1 bg-yellow rounded-md" />
          </button>
          <div
            className={`px-4 py-4 absolute top-[68px] right-0 left-0 bg-[rgba(0,0,0,0.95)]  transition-opacity ease-in-out duration-300 ${
              props.menuOpened ? "opacity-100" : "invisible opacity-0"
            }`}
          >
            <div className="flex flex-col gap-4 md:gap-8 ">
              {props.children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function NFTDetailPlayer() {
  return (
    <div className=" flex flex-col md:flex-row-reverse h-full">
      <div className="h-full">
        <img
          src={MintAPlayer}
          alt={"Mint a Player"}
          className="object-contain h-32 md:h-48 mx-auto"
        />
        <div className="mt-6">
          <h4 className="text-xl font-futura text-right uppercase">
            Be a star player
          </h4>
          <p className="font-futuralight text-xs md:text-base text-right">
            Become a celebrity and compete at the highest level. Sign for a team
            and negotiate your salary every new season. Depending on your
            position and ability, you will increase your team's chances to win.
          </p>
        </div>
        <div className="flex md:block">
          <img
            src={Players}
            className="object-contain max-h-52 md:hidden mt-auto"
            alt="Supporters"
          />
          <div className="w-full">
            <div className="mt-6 ">
              <h4 className="text-xl font-futura text-right">BENEFITS</h4>

              <ul>
                <li className="font-futuralight text-xs md:text-base text-right">
                  - Season Salary according to player contract
                </li>
                <li className="font-futuralight text-xs md:text-base text-right">
                  - % of club prizes according to player contract and team
                  performance
                </li>
                <li className="font-futuralight text-xs md:text-base text-right">
                  - Opportunity to be part of the NFT soccer hall of fame
                </li>
              </ul>
            </div>
            <div className="mt-6 mb-8">
              <h4 className="text-xl font-futura text-right">OVERVIEW</h4>
              <ul>
                <li className="font-futuralight text-xs md:text-base text-right">
                  - Supply: 640
                </li>
                <li className="font-futuralight text-xs md:text-base text-right">
                  - Price: 0.15 ETH + Gas
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <img
        src={Players}
        className="object-contain mx-0 mt-auto pr-8 max-h-[30rem] hidden md:block "
        alt="Supporters"
      />
    </div>
  );
}

function NFTDetailPlayerMobile() {
  return (
    <div className="flex flex-col min-h-full">
      <div className="flex-1 flex items-center">
        <img
          src={MintAPlayer}
          alt={"Mint a Player"}
          className="object-contain h-32 m-auto"
        />
      </div>
      <div>
        <h4 className="text-xl font-futura text-right uppercase">
          Be a star player
        </h4>
        <p className="font-futuralight text-xs md:text-base text-right">
          Become a celebrity and compete at the highest level. Sign for a team
          and negotiate your salary every new season. Depending on your position
          and ability, you will increase your team's chances to win.
        </p>
      </div>
      <div className="flex flex-1">
        <img
          src={Players}
          className="object-contain max-h-52 md:hidden mt-auto"
          alt="Supporters"
        />
        <div className="w-full">
          <div className="mt-8">
            <h4 className="text-xl font-futura text-right">BENEFITS</h4>

            <ul>
              <li className="font-futuralight text-xs md:text-base text-right">
                - Season Salary according to player contract
              </li>
              <li className="font-futuralight text-xs md:text-base text-right">
                - % of club prizes according to player contract and team
                performance
              </li>
              <li className="font-futuralight text-xs md:text-base text-right">
                - Opportunity to be part of the NFT soccer hall of fame
              </li>
            </ul>
          </div>
          <div className="mt-8 mb-8">
            <h4 className="text-xl font-futura text-right">OVERVIEW</h4>
            <ul>
              <li className="font-futuralight text-xs md:text-base text-right">
                - Supply: 640
              </li>
              <li className="font-futuralight text-xs md:text-base text-right">
                - Price: 0.15 ETH + Gas
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

function NFTDetailFanMobile() {
  return (
    <div className="flex flex-col min-h-full">
      <div className="flex-1 flex items-center">
        <img
          src={MintAFan}
          alt={"Mint a Player"}
          className="object-contain h-32 mx-auto"
        />
      </div>
      <div>
        <h4 className="text-xl font-futura text-right">MANAGE A CLUB</h4>
        <p className="font-futuralight text-xs md:text-base text-right">
          Clubs will work as a DAO (decentralized autonomous organization) where
          all FANS will have voting rights to make all the decisions concerning
          the future of their club.
        </p>
      </div>
      <div className="flex flex-1">
        <img
          src={Supporters}
          className="object-contain max-h-52 mt-auto"
          alt="Supporters"
        />
        <div className="w-full">
          <div className="mt-8">
            <h4 className="text-xl font-futura text-right">BENEFITS</h4>
            <ul>
              <li className="font-futuralight text-xs md:text-base text-right">
                - Rewards for early adopters
              </li>
              <li className="font-futuralight text-xs md:text-base text-right">
                - Rewards for club performance
              </li>
              <li className="font-futuralight text-xs md:text-base text-right">
                - Voting rights in club decisions
              </li>
              <li className="font-futuralight text-xs md:text-base text-right">
                - Free access to see tournaments in the Metaverse
              </li>
              <li className="font-futuralight text-xs md:text-base text-right">
                - Become part of a passionate and lifetime experience
              </li>
            </ul>
          </div>
          <div className="mt-8">
            <h4 className="text-xl font-futura text-right">OVERVIEW</h4>
            <ul>
              <li className="font-futuralight text-xs md:text-base text-right">
                - Supply: 1.000 per team
              </li>
              <li className="font-futuralight text-xs md:text-base text-right mb-8 ">
                - Price: 0.06 ETH + Gas.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

function NFTDetailFan() {
  return (
    <div className="flex flex-col md:flex-row-reverse h-full">
      <div>
        <img
          src={MintAFan}
          alt={"Mint a Player"}
          className="object-contain h-32 md:h-48 mx-auto"
        />
        <div className="mt-6">
          <h4 className="text-xl font-futura text-right">MANAGE A CLUB</h4>
          <p className="font-futuralight text-xs md:text-base text-right">
            Clubs will work as a DAO (decentralized autonomous organization)
            where all FANS will have voting rights to make all the decisions
            concerning the future of their club.
          </p>
        </div>
        <div className="flex md:block">
          <img
            src={Supporters}
            className="object-contain max-h-52 md:hidden mt-auto"
            alt="Supporters"
          />
          <div className="w-full">
            <div className="mt-6">
              <h4 className="text-xl font-futura text-right">BENEFITS</h4>
              <ul>
                <li className="font-futuralight text-xs md:text-base text-right">
                  - Rewards for early adopters
                </li>
                <li className="font-futuralight text-xs md:text-base text-right">
                  - Rewards for club performance
                </li>
                <li className="font-futuralight text-xs md:text-base text-right">
                  - Voting rights in club decisions
                </li>
                <li className="font-futuralight text-xs md:text-base text-right">
                  - Free access to see tournaments in the Metaverse
                </li>
                <li className="font-futuralight text-xs md:text-base text-right">
                  - Become part of a passionate and lifetime experience
                </li>
              </ul>
            </div>
            <div className="mt-6">
              <h4 className="text-xl font-futura text-right">OVERVIEW</h4>
              <ul>
                <li className="font-futuralight text-xs md:text-base text-right">
                  - Supply: 1.000 per team
                </li>
                <li className="font-futuralight text-xs md:text-base text-right mb-8 ">
                  - Price: 0.06 ETH + Gas.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <img
        src={Supporters}
        className="object-contain mx-0 mt-auto pr-8 max-h-[30rem] hidden md:block"
        alt="Supporters"
      />
    </div>
  );
}

function PlayingModeSection(props: {
  onClick: () => void;
  onClick1: () => void;
}) {
  return (
    <div
      className="w-full bg-no-repeat bg-cover flex overflow-hidden min-w-full h-full"
      style={{ backgroundImage: `url(${BG2})` }}
    >
      <div className="px-4 md:px-8 mx-auto h-full  max-w-screen-2xl flex flex-col">
        <div className="md:hidden w-1/3 my-auto md:mt-8 min-w-[16rem] m-auto">
          <img
            src={PlayingMode}
            alt={"Playing mode"}
            className=" object-contain"
          />
        </div>
        <div className="flex pt-4 gap-4 md:gap-0 justify-between mx-auto mt-auto">
          <div
            className="flex items-center flex-col hover:scale-105 transition-all duration-150 ease-in-out cursor-pointer"
            onClick={props.onClick}
          >
            <div>
              <div className="pb-16 mb:pb-8 py-8  flex items-center justify-center">
                <button className="bg-yellow rounded-md py-2 px-4 text-black font-aldo text-xl md:text-4xl hover:scale-105 transition-all duration-150 ease-in-out">
                  Manage a Club
                </button>
              </div>
            </div>
            <img
              src={Supporters}
              className=" object-contain mt-auto md:max-h-[32rem] "
              alt="SUPPORTERS"
            />
          </div>
          <div className="hidden md:block w-1/3 mt-48 min-w-[16rem]">
            <img
              src={PlayingMode}
              alt={"Playing mode"}
              className=" object-contain"
            />
          </div>
          <div
            className="flex items-center flex-col hover:scale-105 transition-all duration-150 ease-in-out cursor-pointer"
            onClick={props.onClick1}
          >
            <div>
              <div className="pb-16 mb:pb-8 py-8 flex items-center justify-center">
                <button className="bg-yellow rounded-md py-2 px-4 text-black font-aldo text-xl md:text-4xl hover:scale-105 transition-all duration-150 ease-in-out">
                  Become a Star
                </button>
              </div>
            </div>
            <img
              src={Players}
              className=" object-contain mt-auto md:max-h-[32rem]"
              alt="PLAYERS"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const FAQText = (props: { children: React.ReactNode; selected: boolean }) => (
  <div
    className={`font-futura text-white overflow-hidden transition-all ease-in-out duration-500  ${
      props.selected ? "max-h-96" : "max-h-0"
    }`}
  >
    {props.children}
  </div>
);

const FAQButton = (props: {
  children: React.ReactNode;
  onClick: () => void;
}) => (
  <button
    className="group font-futura text-yellow text-left flex items-center"
    onClick={props.onClick}
  >
    <div className="h-4 w-4 flex items-center justify-center mr-4 text-black rounded-full bg-yellow ">
      +
    </div>
    <span className="group-hover:underline ">{props.children}</span>
  </button>
);

function App() {
  const [nftSelected, setNftSelected] = useState<null | {
    index: number;
    isVisible: boolean;
  }>(null);
  const [menuOpened, setMenuOpened] = useState(false);
  const [faqSelected, setFaqSelected] = useState<number[]>([]);

  const toggleFaq = (index: number) => {
    if (faqSelected.some((x) => x === index)) {
      setFaqSelected((selected) => selected.filter((x) => x !== index));
      return;
    }
    setFaqSelected((selected) => [...selected, index]);
  };

  return (
    <div>
      <div
        className="w-full bg-no-repeat bg-cover"
        style={{ backgroundImage: `url(${BG1})` }}
      >
        <div id="home">
          <Header menuOpened={menuOpened} setMenuOpened={setMenuOpened}>
            <HeaderItem href="#home">HOME</HeaderItem>
            <HeaderItem href="#collections">COLLECTIONS</HeaderItem>
            <HeaderItem href="Whitepaper1.1.pdf" target="_blank">
              WHITE PAPER
            </HeaderItem>
            <HeaderItem href="#team">TEAM</HeaderItem>
            <HeaderItem href="#faqs">FAQS</HeaderItem>
          </Header>
          <div className="px-4" onClick={() => setMenuOpened(false)}>
            <img
              src={LogoPagina}
              alt={"logo"}
              className="mx-auto w-full max-w-[500px] object-contain"
            />
            <h2 className="text-white font-futura pb-16 text-center max-w-2xl m-auto uppercase min-w-full">
              Did you ever dream of managing your own soccer team or becoming a
              soccer superstar?
              <br />
              Introducing ROAD TO GLORY, the world's first decentralized soccer
              league in NFT format.
            </h2>
          </div>
        </div>
      </div>
      <div onClick={() => setMenuOpened(false)}>
        <div className="overflow-hidden" id={"collections"}>
          <div
            className={`hidden md:flex transition-transform duration-500 min-w-screen ${
              !nftSelected?.isVisible ? "-translate-x-full" : ""
            }`}
          >
            <div className={`min-w-full`}>
              <div
                className={`w-full bg-no-repeat bg-cover h-full`}
                style={{
                  backgroundImage: `url(${
                    nftSelected?.index === 1 ? MintAPLayerBg : MintAFanBg
                  })`,
                }}
              >
                <div className="px-8 pt-8 max-w-screen-2xl relative m-auto h-full">
                  <button
                    className="absolute top-0 right-0 m-8 z-10 text-4xl font-futura"
                    onClick={() =>
                      setNftSelected((nft) => ({
                        index: nft!.index,
                        isVisible: false,
                      }))
                    }
                  >
                    X
                  </button>
                  {nftSelected?.index === 1 ? (
                    <NFTDetailPlayer />
                  ) : (
                    <NFTDetailFan />
                  )}
                </div>
              </div>
            </div>
            <div className={`min-w-full`}>
              <PlayingModeSection
                onClick={() => setNftSelected({ index: 2, isVisible: true })}
                onClick1={() => setNftSelected({ index: 1, isVisible: true })}
              />
            </div>
          </div>
          <div className="md:hidden ">
            <div className="h-[calc(max(min(100vh,830px),550px))]">
              <PlayingModeSection
                onClick={() => (window.location.href = "#fan")}
                onClick1={() => (window.location.href = "#player")}
              />
            </div>
            <div
              className={`w-full bg-no-repeat bg-cover  px-8 border-b-2 border-b-black h-[calc(max(min(100vh,830px),550px))] `}
              style={{ backgroundImage: `url(${MintAPLayerBg})` }}
              id="player"
            >
              <NFTDetailPlayerMobile />
            </div>
            <div
              className={`w-full bg-no-repeat bg-cover px-8 h-[calc(max(min(100vh,830px),550px))]`}
              style={{ backgroundImage: `url(${MintAFanBg})` }}
              id="fan"
            >
              <NFTDetailFanMobile />
            </div>
          </div>
        </div>

        <div
          className="w-full bg-no-repeat bg-cover"
          style={{ backgroundImage: `url(${BG3})` }}
          id="whitePaper"
        >
          <div className="px-8 py-32 flex items-center justify-center">
            <a
              href="Whitepaper1.1.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-yellow rounded-md py-4 px-4 text-black font-aldo text-4xl hover:scale-105 transition-all duration-150 ease-in-out"
            >
              White Paper
            </a>
          </div>
        </div>

        <div
          className="w-full bg-no-repeat bg-cover "
          style={{ backgroundImage: `url(${BG4})` }}
          id="team"
        >
          <div className="px-8 py-12 md:py-24 flex flex-col items-center max-w-screen-2xl mx-auto">
            <img src={OurTeam} className="w-1/4 min-w-[16rem] mb-8" />

            <div className="grid gap-4 md:gap-6 grid-cols-4 w-full items-start">
              <div className="flex flex-col items-center col-span-2 md:col-span-1">
                <img
                  src={Supporter1}
                  className=" object-contain mt-auto max-h-40 md:max-h-60"
                  alt="TEAMS"
                />
                <h4 className="font-aldo text-yellow text-2xl md:text-3xl mt-2">
                  Anguila
                </h4>
                <p className="text-white font-futura text-xs md:text-sm">CEO</p>
                {/*<a*/}
                {/*  className="group flex gap-2 items-center mt-2"*/}
                {/*  href="https://www.linkedin.com/in/gaston-abramoff/"*/}
                {/*  target="_blank"*/}
                {/*  rel="noopener noreferrer"*/}
                {/*>*/}
                {/*  <img src={Linkedin} alt="Twitter" className="h-2 md:h-4" />*/}
                {/*  <span className="group-hover:underline text-yellow font-futura text-xs md:text-sm">*/}
                {/*    LinkedIn*/}
                {/*  </span>*/}
                {/*</a>*/}
              </div>
              <div className="flex flex-col items-center col-span-2 md:col-span-1">
                <img
                  src={Supporter2}
                  className=" object-contain mt-auto max-h-40 md:max-h-60"
                  alt="TEAMS"
                />
                <h4 className="font-aldo text-yellow text-2xl md:text-3xl mt-2">
                  The Sheriff
                </h4>
                <p className="text-white font-futura text-xs md:text-sm">
                  ARTIST
                </p>
                {/*<a*/}
                {/*  className="group flex gap-2 items-center mt-2"*/}
                {/*  href="https://www.linkedin.com/in/franciscoirodriguez/"*/}
                {/*  target="_blank"*/}
                {/*  rel="noopener noreferrer"*/}
                {/*>*/}
                {/*  <img src={Linkedin} alt="Twitter" className=" h-2 md:h-4" />*/}
                {/*  <span className="group-hover:underline text-yellow font-futura text-xs md:text-sm">*/}
                {/*    LinkedIn*/}
                {/*  </span>*/}
                {/*</a>*/}
              </div>
              <div className="flex flex-col items-center col-span-2 md:col-span-1">
                <img
                  src={Supporter3}
                  className=" object-contain mt-auto max-h-40 md:max-h-60"
                  alt="TEAMS"
                />
                <h4 className="font-aldo text-yellow text-2xl md:text-3xl mt-2">
                  Slasher
                </h4>
                <p className="text-white font-futura text-xs md:text-sm">CMO</p>
                {/*<a*/}
                {/*  className="group flex gap-2 items-center mt-2"*/}
                {/*  href="https://www.linkedin.com/in/santiagosappia/"*/}
                {/*  target="_blank"*/}
                {/*  rel="noopener noreferrer"*/}
                {/*>*/}
                {/*  <img src={Linkedin} alt="Twitter" className=" h-2 md:h-4" />*/}
                {/*  <span className="group-hover:underline text-yellow font-futura text-xs md:text-sm">*/}
                {/*    LinkedIn*/}
                {/*  </span>*/}
                {/*</a>*/}
              </div>
              <div className="flex flex-col items-center col-span-2 md:col-span-1">
                <img
                  src={Supporter4}
                  className=" object-contain mt-auto max-h-40 md:max-h-60"
                  alt="TEAMS"
                />
                <h4 className="font-aldo text-yellow text-2xl md:text-3xl mt-2">
                  Alligator
                </h4>
                <p className="text-white font-futura text-xs md:text-sm text-center">
                  COMMUNITY MANAGER
                </p>
              </div>
            </div>
            <div className="flex items-center gap-4 mt-10">
              <span className="font-futura text-sm md:text-base text-white">
                In association with
              </span>
              <a
                href="https://solucionetglobal.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={Solucion} alt="Solucionet" className="w-32 md:w-40" />
              </a>
            </div>
          </div>
        </div>

        <div
          className="w-full bg-no-repeat bg-cover"
          style={{ backgroundImage: `url(${BG5})` }}
          id="faqs"
        >
          <div className="px-8 py-8 max-w-screen-2xl mx-auto">
            <h3 className="font-aldo text-yellow text-4xl mb-6">FAQS:</h3>
            <div className="my-4">
              <FAQButton onClick={() => toggleFaq(0)}>
                What is Road to glory?
              </FAQButton>
              <FAQText selected={faqSelected.some((x) => x === 0)}>
                Road to glory is the world's first decentralized soccer league
                in NFT format. It is an opportunity to play and follow your
                passion from different playing modes. Is a collection of 640
                players and 1000 fans per team that live on the blockchain as an
                NFT. Each NFT is constructed from an algorithm that takes
                different traits and stitches them together. There are over 1
                trillion possible combinations so you can be assured that every
                single player, and fan is unique.
              </FAQText>
            </div>
            <div className="my-4">
              <FAQButton onClick={() => toggleFaq(1)}>
                What time is the kickoff? When can I Buy?
              </FAQButton>
              <FAQText selected={faqSelected.some((x) => x === 1)}>
                The main sale will take place in June.
              </FAQText>
            </div>
            <div className="my-4">
              <FAQButton onClick={() => toggleFaq(2)}>
                How much does it cost each NFT?
              </FAQButton>
              <FAQText selected={faqSelected.some((x) => x === 2)}>
                <ul>
                  <li>Player 0.15 ETH + Gas.</li>
                  <li>Fan 0.06 ETH + Gas.</li>
                </ul>
              </FAQText>
            </div>
            <div className="my-4">
              <FAQButton onClick={() => toggleFaq(3)}>
                How can I Get an NFT?
              </FAQButton>
              <FAQText selected={faqSelected.some((x) => x === 3)}>
                It will be available to mint on our website through an initial
                sale. Once it is sold out, you can get a RTG NFT on the
                secondary market (on OpenSea) only.
              </FAQText>
            </div>
            <div className="my-4">
              <FAQButton onClick={() => toggleFaq(4)}>
                How to purchase one?
              </FAQButton>
              <FAQText selected={faqSelected.some((x) => x === 4)}>
                The easiest way is to install a MetaMask extension in your
                Chrome browser and link your Ethereum wallet to it. Ensure you
                have enough ETH in your wallet to cover the cost of a NFT plus
                the associated transaction (gas) fees. Approve the transaction
                on MetaMask and you are all set.
              </FAQText>
            </div>
            <div className="my-4">
              <FAQButton onClick={() => toggleFaq(5)}>
                Where can I view my NFT?
              </FAQButton>
              <FAQText selected={faqSelected.some((x) => x === 5)}>
                Once minted, you'll just need to login into your OpenSea account
                to view your NFTs.
              </FAQText>
            </div>
            <div className="my-4">
              <FAQButton onClick={() => toggleFaq(6)}>
                When will I be able to see my NFT?
              </FAQButton>
              <FAQText selected={faqSelected.some((x) => x === 6)}>
                We will reveal each NFT role 48 hours after the public mint.
                Before that, all the NFTs will remain hidden!
              </FAQText>
            </div>
            <div className="my-4">
              <FAQButton onClick={() => toggleFaq(7)}>
                Do you have a long-term roadmap?
              </FAQButton>
              <FAQText selected={faqSelected.some((x) => x === 7)}>
                Of course, The RTG Football Association is a long-term project.
                We have the strong desire to build the strongest community in
                the NFT world. We plan to organize the world's most interactive
                sporting events and private parties on the Metaverse with all
                the biggest celebrities.
              </FAQText>
            </div>
          </div>
        </div>
        <div className="md:hidden bg-yellow p-4 px-8">
          <div className="max-w-screen-2xl m-auto md:px-8">
            <div className="flex items-center justify-end">
              {/*<a*/}
              {/*  className="flex gap-2 items-center"*/}
              {/*  href="mailto:rtgnft@gmail.com"*/}
              {/*>*/}
              {/*  <img src={Envelope} alt="envelope" className="h-6" />*/}
              {/*  <span className="font-din text-black underline">*/}
              {/*    CONTACT US*/}
              {/*  </span>*/}
              {/*</a>*/}
              <div className="flex md:hidden items-center gap-2 md:gap-4 ml-2">
                <HeaderSocial
                  imgSource={Twitter}
                  className="brightness-0 h-6"
                  href="https://twitter.com/Rtgnft"
                />
                <HeaderSocial
                  imgSource={Discord}
                  className="brightness-0 h-6"
                  href="https://discord.gg/roadtoglorynft"
                />
                <HeaderSocial
                  imgSource={Boat}
                  className="brightness-0 h-6"
                  href="https://www.instagram.com/RTGNFT/"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
